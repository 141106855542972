import React from 'react'
import Page from '../components/Page/Page'
import PageHeader from '../components/PageHeader/PageHeader'
import About from '../components/Home/About'
import Training from '../components/Home/Training'
import News from '../components/Home/News'
import Message from '../components/Home/Message'
import University from '../components/Home/University'
import Video from '../components/Home/Video'

const pageTitle = '北越地域医療人養成センター'

const Home = () => {
  return (
    <Page title={pageTitle}>
      <PageHeader />
      <News />
      <Video />
      <About />
      <Training />
      <Message />
      <University />
    </Page>
  )
}
export default Home
