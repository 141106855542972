import React from 'react'
import Content from '../Content/Content'
import { style } from './Video.style'
import SectionTitle from '../SectionTitle/SectioinTitle'
import { commonStyle } from './Index.style'


const Video = () => {
  return (
    <section css={commonStyle.lastSection}>
      <Content>
      <SectionTitle
          sectionSubTitle="VIDEO"
          sectionTitle="紹介動画"
        />
        <div css={style.box}>
          <div>
            <iframe src="https://www.youtube.com/embed/vxKo5lKcQMs?si=pwAFOMTME5aAiMCH" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
            <p css={style.text}>
            北越地域医療人養成センター ～日本の医療の質の向上に向けた取り組みの紹介～
            </p>
          </div>
        </div>
      </Content>
    </section>
  )
}

export default Video
