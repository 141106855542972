import { css } from 'styled-components'
import { minMq, maxMq } from '../../shared/styles/breakPoints'
import variables from '../../shared/styles/variables'

const content = css`
  position: relative;
`

const pageHeader = css`
  overflow-x: hidden;
  padding-bottom: 80px;
  position: relative;

  ${minMq('MD')} {
    padding-bottom: 150px;
  }

  &::before {
    background-color: ${variables.BASE_BGC};
    border-radius: 0 0 50% 50%;
    content: "";
    height: 100%;
    left: 50%;
    position: absolute;
    top: -432px;
    transform: translateX(-50%);
    width: 297vw;

    ${minMq('MD')} {
      top: -352px;
      width: 200vw;
    }

    ${maxMq('SM')} {
      top: -493px;
    }

    ${maxMq('XS')} {
      top: -534px;
    }
  }
`

const wrapSplide = css`
  margin-bottom: 30px;

  ${minMq('MD')} {
    margin-bottom: 64px;
  }
`

const splide = css`
  .splide__pagination {
    bottom: 0;
    justify-content: flex-end;
    margin: 0 auto;
    max-width: 1200px;
    padding-top: 3px;
    position: relative;
    width: 100%;
  
    ${minMq('MD')} {
      padding-top: 10px;
      gap: 3px;
    }

    ${maxMq('MD')} {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

	.splide__pagination__page {
		border-radius: 0;
		height: 3px;
    width: 30px;
  
    ${minMq('MD')} {
      height: 4px;
      width: 80px;
    }

    &.is-active {
      background-color: ${variables.ORANGE};
      transform: none;
    }
	}
`

const topText = css`
  background-color: ${variables.BASE_BGC};
  font-family: 'Noto Serif JP', sans-serif;
  font-size: 6.5rem;
  font-weight: 600;
  left: -16px;
  padding: 0 15px;
  position: absolute;
  top: -143px;

  ${maxMq('LG')} {
    left: 20px;
  }

  ${maxMq(`MD`)} {
    font-size: 2.5rem;
    left: 5px;
    padding: 0 10px;
    top: -66px;
  }
`

const title = css`
  font-family: 'Noto Serif JP', sans-serif;
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 30px;

  ${minMq('MD')} {
    font-size: 3.2rem;
    margin-bottom: 63px;
  }
`

const logoBlock = css`
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: space-between;

  ${maxMq('MD')} {
    align-items: flex-start;
    flex-direction: column;
    gap: 40px;
  }

  > img {
    width: min(30vw, 298px);

    ${maxMq('MD')} {
      display: block;
      margin: 0 auto;
      width: 124px;
    }
  }
`

const wrapLogoText = css`
  font-family: 'Noto Serif JP', sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2.2;

  ${minMq('MD')} {
    font-size: 1.8rem;
  }

  ${maxMq('MD')} {
    order: 1;
  }
`

export const style = {
  content,
  pageHeader,
  wrapSplide,
  splide,
  topText,
  title,
  logoBlock,
  wrapLogoText,
}