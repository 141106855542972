import React from 'react'
import Content from '../Content/Content'
import { style } from './University.style'
import SectionTitle from '../SectionTitle/SectioinTitle'
import { commonStyle } from './Index.style'
import Link from 'next/link'
import { myLink } from '../../shared/styles/myLink'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()
const basePath = publicRuntimeConfig.basePath

const University = () => {
  return (
    <section css={commonStyle.lastSection}>
      <Content>
        <SectionTitle
          sectionSubTitle="UNIVERSITY"
          sectionTitle="各大学の情報"
        />
        <div css={style.box}>
          <div>
            <figure css={style.imgBox}>
              <img
                src={`${basePath}/home/img/university_toyama.png`}
                alt="富山大学サイトの情報の画像"
              />
              <figcaption css={style.caption('blue')}>
                <span>富山大学サイトの情報</span>
              </figcaption>
            </figure>
            <p css={style.text}>
              大学の取り組み、教育プログラム・コース等を掲載しています。
            </p>
            <Link href="/toyama">
              <a css={[myLink, style.link]}>富山大学サイトの情報</a>
            </Link>
          </div>
          <div>
            <figure css={style.imgBox}>
              <img
                src={`${basePath}/home/img/university_niigata.png`}
                alt="新潟大学サイトの情報の画像"
              />
              <figcaption css={style.caption('green')}>
                <span>新潟大学サイトの情報</span>
              </figcaption>
            </figure>
            <p css={style.text}>
              大学の取り組み、教育プログラム・コース等を掲載しています。
            </p>
            <Link href="/niigata">
              <a css={[myLink, style.link]}>新潟大学サイトの情報</a>
            </Link>
          </div>
        </div>
      </Content>
    </section>
  )
}

export default University
