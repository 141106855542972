import React from 'react'
import { style } from './SectioinTitle.style'

interface OwnProps {
  sectionSubTitle: string
  sectionTitle: string
  className?: string
}

type Props = OwnProps

const SectionTitle: React.FC<Props> = (props) => {
  return (
    <header className={props.className || ''} css={style.sectionHeader}>
      <h2 css={style.sectionTitleBlock}>
        <span css={style.sectionSubTitle}>{props.sectionSubTitle}</span>
        <span css={style.sectionTitle}>{props.sectionTitle}</span>
      </h2>
    </header>
  )
}

export default SectionTitle
