import { css } from 'styled-components'
import { maxMq, minMq } from '../../shared/styles/breakPoints'
import variables from '../../shared/styles/variables'

const trainingList = css`
  > li + li {
    margin-top: 50px;

    ${minMq('MD')} {
      margin-top: 80px;
    }
  }
`

const box = css`
  display: flex;

  ${minMq('MD')} {
    gap: 40px;
    justify-content: space-between;
    align-items: center;
  }

  ${maxMq('MD')} {
    gap: 30px;
    flex-direction: column;
  }

  > img {
    display: block;

    ${maxMq('MD')} {
      margin: 0 auto;
      order: 1;
    }
  }
`

const textBlock = css`
  line-height: 1.7;

  ${minMq('MD')} {
    max-width: 480px;
    width: 100%;
  }
`

const textOnlyBlock = css`
  line-height: 1.7;
`

const smImg = css`
  width: min(440px, 48vw);

  ${maxMq('MD')} {
    max-width: 440px;
    width: 100%;
  }
`

const baseImg = css`
  width: min(480px, 48vw);

  ${maxMq('MD')} {
    max-width: 480px;
    width: 100%;
  }
`

const textBlockSubTitle = css`
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 15px;

  ${minMq('MD')} {
    font-size: 1.8rem;
    margin-bottom: 22px;
  }
`

const competency = css`
  background-color: ${variables.BASE_BGC};
  margin-top: 25px;
  padding: 24px;

  ${minMq('MD')} {
    margin-top: 40px;
    padding: 40px;
  }
`

const competencyTitle = css`
  border-bottom: 1px solid ${variables.BASE_TXC};
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 15px;

  ${minMq('MD')} {
    font-size: 2rem;
    margin-bottom: 20px;
  }
`

const competencyList = css`
  column-gap: 40px;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  row-gap: 20px;

  ${minMq('MD')} {
    grid-template-columns: repeat(auto-fit, 440px);
  }
`

const competencyListTitle = css`
  font-weight: 700;
  margin-bottom: 3px;

  ${minMq('MD')} {
    margin-bottom: 6px;
  }
`

const competencyListText = css`
  padding-left: 1em;
`

export const style = {
  trainingList,
  box,
  textBlock,
  textOnlyBlock,
  smImg,
  baseImg,
  textBlockSubTitle,
  competency,
  competencyTitle,
  competencyList,
  competencyListTitle,
  competencyListText,
}
