import React from 'react'
import Content from '../Content/Content'
import { style } from './Training.style'
import SectionTitle from '../SectionTitle/SectioinTitle'
import { commonStyle } from './Index.style'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()
const basePath = publicRuntimeConfig.basePath

const Training = () => {
  return (
    <section css={commonStyle.homeSection}>
      <Content>
        <SectionTitle
          sectionSubTitle="文部科学省「ポストコロナ時代の医療人材養成拠点形成事業」"
          sectionTitle="臨床医学と社会医学を駆使した地域を守る医療人の養成"
        />
        <ol css={style.trainingList}>
          <li>
            <div css={style.box}>
              <div css={style.textBlock}>
                <h3 css={commonStyle.subHead('yellow')}>
                  <span>1．北越地域医療人養成センターの設置</span>
                </h3>
                <p>
                  富山大学と新潟大学の教員を主たる構成員とする北越地域医療人養成センターを設置し、本事業を統括します。両大学が開発したオンデマンド教材を含む教育コンテンツを集積して、両大学の医療人教育に提供します。
                </p>
              </div>
              <img
                src={`${basePath}/home/img/training01.png`}
                alt="北越地域医療人養成センターの設置の画像"
                css={style.smImg}
              />
            </div>
          </li>
          <li>
            <div css={style.box}>
              <img
                src={`${basePath}/home/img/training02.png`}
                alt="入口戦略の画像"
                css={style.baseImg}
              />
              <div css={style.textBlock}>
                <h3 css={commonStyle.subHead('yellow')}>
                  <span>2．入口戦略</span>
                </h3>
                <h4 css={style.textBlockSubTitle}>
                  地域を守ることに関心を持つ高校生の増加
                </h4>
                <p>
                  大学入学前の戦略として、教育委員会等と連携して、高校生を対象としたインターンシップや高校訪問、模擬講義等を実施することで、地域医療や地域を守ることに関心を持つ高校生を増加させます。
                </p>
              </div>
            </div>
          </li>
          <li>
            <div css={style.box}>
              <div css={style.textBlock}>
                <h3 css={commonStyle.subHead('yellow')}>
                  <span>3．教育戦略</span>
                </h3>
                <p>
                  大学入学後の戦略として、全医学生必修のエッセンシャルコースと、地域枠学生等を対象としたアドバンスドコースを設定します。エッセンシャルコースでは、下記の４つのコンピテンシー（現場での実践的能力）を修得します。アドバンスドコースでは、医師不足を含む様々な課題から地域医療構想の重点支援区域となっている新潟県上越地域においてフィールドワークを行い、各種統計情報の分析から施策の提言までを学修する統合型教育を行います。
                </p>
              </div>
              <img
                src={`${basePath}/home/img/training03.png`}
                alt="教育戦略の画像"
                css={style.baseImg}
              />
            </div>
            <div css={style.competency}>
              <h4 css={style.competencyTitle}>4つのコンピテンシー</h4>
              <ul css={style.competencyList}>
                <li>
                  <p css={style.competencyListTitle}>
                    ■ 地域医療プロフェッショナリズム
                  </p>
                  <p css={style.competencyListText}>
                    多職種協働を含めた地域を守るマインド
                  </p>
                </li>
                <li>
                  <p css={style.competencyListTitle}>■ 臨床医学的能力</p>
                  <p css={style.competencyListText}>
                    地域を医療面から守るうえで重要な臨床医学的能力
                    <br />
                    ※総合診療、感染症など
                  </p>
                </li>
                <li>
                  <p css={style.competencyListTitle}>■ 社会医学的能力</p>
                  <p css={style.competencyListText}>
                    地域をシステム面から守るうえで重要な社会医学的能力
                    <br />
                    ※疫学・公衆衛生学、救急医学・災害医学など
                  </p>
                </li>
                <li>
                  <p css={style.competencyListTitle}>
                    ■ 情報通信技術（ICT）運用能力
                  </p>
                  <p css={style.competencyListText}>
                    オンライン会議や遠隔医療等に対応できる能力
                  </p>
                </li>
              </ul>
            </div>
          </li>
          <li>
            <div css={style.box}>
              <img
                src={`${basePath}/home/img/training04.png`}
                alt="出口戦略の画像"
                css={style.baseImg}
              />
              <div css={style.textBlock}>
                <h3 css={commonStyle.subHead('yellow')}>
                  <span>4．出口戦略</span>
                </h3>
                <h4 css={style.textBlockSubTitle}>
                  継続性・再現性・循環性のある
                  <br />
                  地域を守る医療人養成モデル
                </h4>
                <p>
                  大学卒業後の戦略として、本事業の修了者による学生指導を含めた地域を守る医療人の循環型かつ継続的な育成を行います。また、専門医（総合診療、救急医学、感染症、社会医学系専門医）取得などのキャリア形成を支援します。
                </p>
              </div>
            </div>
          </li>
          <li>
            <div css={style.textOnlyBlock}>
              <h3 css={commonStyle.subHead('yellow')}>
                <span>5．全国展開</span>
              </h3>
              <p>
                本事業で構築された「臨床医学と社会医学を駆使して地域を守る医療人」の人材養成モデルを全国に向けて発信することにより、日本の医療の質の向上につなげます。
                <br />
                <br />
                両大学医学部は、毎年合計で二百数十名の医師を輩出しています。
                <br />
                本事業を通じて、ポストコロナ時代に対応できる総合的な能力を持った医師を養成します。
              </p>
            </div>
          </li>
        </ol>
      </Content>
    </section>
  )
}

export default Training
