import { css } from 'styled-components'
import { minMq } from '../../shared/styles/breakPoints'

const aboutSection = css`
  padding-bottom: 40px;

  ${minMq('MD')} {
    padding-bottom: 60px;
  }
`

const mainText = css`
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2.1;

  ${minMq('MD')} {
    font-size: 1.8rem;
  }
`

export const style = {
  aboutSection,
  mainText
}