import { css } from 'styled-components'
import { maxMq, minMq } from '../../shared/styles/breakPoints'
import variables from '../../shared/styles/variables'

const box = css`
  display: grid;
  gap: 60px 40px;

  ${minMq('MD')} {
    grid-template-columns: repeat(auto-fit, 480px);
  }

  ${maxMq('LG')} {
    grid-template-columns: repeat(1, auto);
  }
`

const imgBox = css`
  margin-bottom: 32px;
  position: relative;

  ${minMq('MD')} {
    margin-bottom: 40px;
  }

  > img {
    width: min(100%, 480px);
  }
`

const caption = (color: "blue" | "green") => {
  const lineColor = color === "blue" ? variables.DARK_BLUE : variables.DARK_GREEN
  return css`
    bottom: 5px;
    color: ${variables.WHITE};
    font-size: 1.6rem;
    font-weight: 700;
    left: 0;
    position: absolute;

    ${minMq('MD')} {
      font-size: 2.4rem;
    }

    > span {
      background: linear-gradient(transparent 55%, ${lineColor} 50%);
      padding: 0 10px 5px;

      ${minMq('MD')} {
        background: linear-gradient(transparent 41%, ${lineColor} 50%);
        padding: 0 20px 9px;
      }
    }
  `
}

const text = css`
  line-height: 1.63;
  margin-bottom: 32px;

  ${minMq('MD')} {
    margin-bottom: 50px;
  }
`

const link = css`
  margin-left: auto;
  width: 190px;

  ${minMq('MD')} {
    width: 220px;
  }
`

export const style = {
  box,
  imgBox,
  caption,
  text,
  link
}