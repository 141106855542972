import { css } from 'styled-components'
import { minMq } from '../../shared/styles/breakPoints'
import variables from '../../shared/styles/variables'
import { Tag, getColor } from '../../utils/news'

const tags = css`
  display: flex;
  gap: 5px;

  > input {
    display: none;
  }
`

const tag = (tag: Tag) => {
  const color = getColor(tag)

  return css`
    border: 2px solid ${color};
    border-radius: 14px;
    color: ${color};
    cursor: pointer;
    font-weight: 500;
    padding: 0 15px;

    ${minMq('MD')} {
      padding: 0 27px;
    }
  `
}

const currentTag = (tag: Tag) => {
  return css`
    background-color: ${getColor(tag)};
    color: ${variables.WHITE};
  `
}

export const style = {
  tags,
  tag,
  currentTag
}