import { css } from 'styled-components'
import { maxMq, minMq } from '../../shared/styles/breakPoints'

const box = css`
  text-align: center;

  iframe{
    height: 50vh;
    width: 80%;
    border: none;
  }

  ${maxMq('SM')} {
    iframe{
      height: 25vh;
      width: 100%;
    }
`

const text = css`
  line-height: 1.63;
  margin-bottom: 32px;

  ${minMq('MD')} {
    font-size: 18px;
    margin-bottom: 50px;
  }
`

const link = css`
  margin-left: auto;
  width: 190px;

  ${minMq('MD')} {
    width: 220px;
  }
`

export const style = {
  box,
  text,
  link
}
