import { css } from 'styled-components'
import { minMq } from '../../shared/styles/breakPoints'
import variables from '../../shared/styles/variables'

const wrapSectionTitle = css`
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 30px;

  ${minMq('MD')} {
    gap: 60px;
    margin-bottom: 60px;
  }
`

const sectionTitle = css`
  margin: 0;
`

const bodyBlock = css`
  align-items: flex-start;
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  margin-top: 5px;

  ${minMq('MD')} {
    gap: 40px;
    grid-template-columns: auto 260px;
    margin-top: -20px;
  }
`

const linksBlock = css`
  background-color: ${variables.BASE_BGC};
  border-radius: 10px;
  padding: 30px;
`

const linksBlockTitle = css`
  font-size: 1.7rem;
  font-weight: 700;
  margin-bottom: 20px;

  ${minMq('MD')} {
    font-size: 2.4rem;
    margin-bottom: 40px;
  }
`

const link = css`
  + a {
    margin-top: 24px;

    ${minMq('MD')} {
      margin-top: 30px;
    }
  }
`

export const style = {
  wrapSectionTitle,
  sectionTitle,
  bodyBlock,
  linksBlock,
  linksBlockTitle,
  link
}