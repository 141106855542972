import { css } from 'styled-components'
import { minMq } from '../../shared/styles/breakPoints'

const sectionHeader = css`
  margin-bottom: 30px;

  ${minMq('MD')} {
    margin-bottom: 60px;
  }
`

const sectionTitleBlock = css`
  > span {
    display: block;
  }
`

const sectionSubTitle = css`
  font-family: 'Lato', 'Noto Sans JP', sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 7px;

  ${minMq('MD')} {
    font-size: 2rem;
    margin-bottom: 12px;
  }
`

const sectionTitle = css`
  font-size: 1.8rem;
  font-weight: 700;

  ${minMq('MD')} {
    font-size: 3.2rem;
  }
`

export const style = {
  sectionHeader,
  sectionTitleBlock,
  sectionSubTitle,
  sectionTitle
}
