import React, { useEffect } from 'react'
import Link from 'next/link'
import { format } from 'date-fns'
import { style } from './Items.style'
import { Category, getCategoryJp, Query } from '../../utils/news'
import { addHoverEvent } from '../../utils/addHoverEvent'

type Title = {
  rendered: string
}

type OwnProps = {
  id: number
  date: string
  category: Category[]
  title: Title
  query?: Query
}

const Item: React.FC<OwnProps> = (props) => {
  const { id, date, category, title, query } = props

  useEffect(() => {
    addHoverEvent()
  }, [])

  return (
    <Link
      as={`/news/detail?nid=${id}`}
      href={{ pathname: `/news/detail`, query }}
    >
      <a css={style.items}>
        <p css={style.date}>{format(new Date(date), 'yyyy.MM.dd')}</p>
        <div>
          <div css={style.categories}>
            {category.map((val, i) => {
              return (
                <p key={i} css={style.category(val)}>
                  {getCategoryJp(val)}
                </p>
              )
            })}
          </div>
          <p css={style.title}>{title.rendered}</p>
        </div>
      </a>
    </Link>
  )
}

export default Item
