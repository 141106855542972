import React, { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import axios from 'axios'
import Content from '../Content/Content'
import { style } from './News.style'
import SectionTitle from '../SectionTitle/SectioinTitle'
import { commonStyle } from './Index.style'
import Items from '../News/Items'
import { Tag, tagItems } from '../../utils/news'
import Link from 'next/link'
import NewsTag from '../News/NewsTag'
import { myLink } from '../../shared/styles/myLink'

const News = () => {
  const router = useRouter()
  const [news, setNews] = useState([])
  const [newsLength, setNewsLength] = useState(0)
  const [currentShowTag, setCurrentShowTag] = useState<Tag>('all')

  // 一覧表示
  const setContent = (item: any) => {
    return (
      <Items
        key={item.id}
        id={item.id}
        date={item.date}
        category={item.acf.category}
        title={item.title}
      />
    )
  }

  const getNews = async () => {
    const query = currentShowTag !== 'all' ? `?category=${currentShowTag}` : ''
    const newEndPoint = `${process.env.NEXT_PUBLIC_API_BASE_URL}${process.env.NEXT_PUBLIC_NEWS}${query}`

    await axios
      .get(newEndPoint, {
        params: {
          per_page: process.env.NEXT_PUBLIC_NEWS_HOME_PER_PAGE,
          subcategory: 'news',
        },
      })
      .then((res) => {
        setNews(res.data)
        setNewsLength(res.data.length)
      })
  }

  useEffect(() => {
    if (!router.isReady) return
    getNews()
  }, [currentShowTag])

  return (
    <section css={commonStyle.homeSection}>
      <Content>
        <div css={style.wrapSectionTitle}>
          <SectionTitle
            sectionSubTitle="NEWS"
            sectionTitle="新着情報"
            css={style.sectionTitle}
          />
          <NewsTag
            currentShowTag={currentShowTag}
            setCurrentShowTag={setCurrentShowTag}
          />
        </div>
        <div css={style.bodyBlock}>
          <div>
            {newsLength > 0 ? (
              <div>
                {(() => {
                  return news.map((contentObj) => setContent(contentObj))
                })()}
              </div>
            ) : (
              <p>表示するデータがありません</p>
            )}
          </div>
          <div css={style.linksBlock}>
            <p css={style.linksBlockTitle}>新着一覧へ</p>
            {tagItems.map((item) => {
              return (
                <Link
                  key={item.value}
                  as="/news"
                  href={{ pathname: '/news', query: { tag: item.value } }}
                >
                  <a css={[myLink, style.link]}>{item.text}の新着情報一覧</a>
                </Link>
              )
            })}
          </div>
        </div>
      </Content>
    </section>
  )
}

export default News
