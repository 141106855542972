import React from 'react'
import Content from '../Content/Content'
import { style } from './Message.style'
import SectionTitle from '../SectionTitle/SectioinTitle'
import { commonStyle } from './Index.style'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()
const basePath = publicRuntimeConfig.basePath

const Message = () => {
  return (
    <section
      css={[commonStyle.homeSection, commonStyle.bgcGray, style.massageSection]}
    >
      <Content>
        <SectionTitle sectionSubTitle="MESSAGE" sectionTitle="ご挨拶" />
        <div css={style.box}>
          <div>
            <h3 css={commonStyle.subHead('blue')}>
              <span>富山大学医学部</span>
            </h3>
            <article css={style.textBlock}>
              <h4 css={style.title}>
                臨床医学と社会医学を駆使して地域を守る医療人
                <br />
                －ポストコロナ時代の医療人材養成－
              </h4>
              <p css={style.name}>
                事業責任者&emsp;関根道和（富山大学医学部長）
              </p>
              <div>
                <p css={style.text}>
                  平素より富山大学と新潟大学の医療人材養成に御理解と御支援を賜り感謝申し上げます。
                </p>
                <p css={style.text}>
                  この度、富山大学と新潟大学の連携事業が、文部科学省の令和4年度大学教育再生戦略推進費「ポストコロナ時代の医療人材養成拠点形成事業」に選定されました。
                </p>
                <p css={style.text}>
                  本事業では、「地域と世界で活躍できる医療人を養成する」という共通のミッションを持って地域医療人材を養成してきた富山大学と新潟大学が、富山県から新潟県にまたがる広域ネットワークを形成して、両大学の持つ教育ノウハウを共有して、地域を守る総合的な能力を持った医師を共同で養成します。
                </p>
                <p css={style.text}>
                  本事業は、以下の要素から構成されています。
                </p>
                <ol>
                  <li css={style.newLine}>
                    <p>1.&emsp;北越地域医療人養成センターの設置：</p>
                    <p css={style.text}>
                      富山大学と新潟大学の教員を主たる構成員とする北越地域医療人養成センターを設置し、本事業を統括します。両大学が開発したオンデマンド教材を含む教育コンテンツを集積して、両大学の医療人教育に提供します。
                    </p>
                  </li>
                  <li css={style.newLine}>
                    <p>2.&emsp;入口戦略：</p>
                    <p css={style.text}>
                      大学入学前の戦略として、教育委員会等と連携して、高校生を対象としたインターンシップや高校訪問、模擬講義等を実施することで、地域医療や地域を守ることに関心を持つ高校生を増加させます。
                    </p>
                  </li>
                  <li css={style.newLine}>
                    <p>3.&emsp;教育戦略：</p>
                    <p css={style.text}>
                      大学入学後の戦略として、全医学生必修のエッセンシャルコースと，地域枠学生等を対象としたアドバンスドコースを設定します。エッセンシャルコースでは、下記の４つのコンピテンシー（現場での実践的能力）を修得します。アドバンスドコースでは、医師不足のため地域医療構想の重点支援区域となっている新潟県上越地域のフィールドワークを行って、各種統計情報の分析から施策の提言までを学修する統合型教育を行います。
                    </p>
                    <p css={style.newLine}>4つのコンピテンシー</p>
                    <ul>
                      <li>
                        ①&emsp;地域医療プロフェッショナリズム：&emsp;入学早期からの地域医療に関する講義や実習を通じて、多職種協働を含めた地域を守るマインドを醸成します。
                      </li>
                      <li>
                        ②&emsp;臨床医学的能力：&emsp;コロナ禍でも高度な臨床教育を実施するためにシミュレーション教育を充実させ、地域を医療面から守るうえで重要な臨床医学的能力（特に総合診療や感染症に関する能力）を高めます。
                      </li>
                      <li>
                        ③&emsp;社会医学的能力：&emsp;マネジメントスキルやデータサイエンススキルに関する教育を充実させ、地域をシステム面から守るうえで重要な社会医学的能力（特に疫学、公衆衛生学、救急医学、災害医学に関する能力）を高めます。
                      </li>
                      <li>
                        ④&emsp;情報通信技術（ICT）運用能力：&emsp;デジタルトランスフォーメーション（DX）やICTに関する教育を充実させ、オンライン会議や遠隔医療等に対応できる能力を修得します。
                      </li>
                    </ul>
                  </li>
                  <li css={style.newLine}>
                    <p>4.&emsp;出口戦略： </p>
                    <p css={style.text}>
                      大学卒業後の戦略として、本事業の修了者による学生指導を含めた地域を守る医療人の継続的な育成を行います。また、専門医（総合診療、救急医学、感染症、社会医学系専門医）取得などのキャリア形成を支援します。
                    </p>
                  </li>
                  <li css={style.newLine}>
                    <p>5.&emsp;全国展開：</p>
                    <p css={style.text}>
                      本事業で構築された「臨床医学と社会医学を駆使して地域を守る医療人」の人材養成モデルを全国に向けて発信することにより、日本の医療の質の向上につなげます。
                    </p>
                  </li>
                </ol>
                <p css={[style.text, style.newLine]}>
                  両大学は、毎年合計で二百数十名の医師を輩出しています。本事業を通じて、ポストコロナ時代に対応できる総合的な能力を持った医師を養成します。
                </p>
                <p css={style.text}>
                  今後ともご指導ご鞭撻のほどよろしくお願い申し上げます。
                </p>
              </div>
            </article>
          </div>
          <img
            src={`${basePath}/home/img/message01.png`}
            alt="富山大学医学部のご挨拶の画像"
            css={style.img}
          />
        </div>
        <div css={style.box}>
          <img
            src={`${basePath}/home/img/message02.png`}
            alt="新潟大学医学部のご挨拶の画像"
            css={style.img}
          />
          <div>
            <article>
              <h3 css={commonStyle.subHead('green')}>
                <span>新潟大学医学部</span>
              </h3>
              <p css={style.name}>
                副事業責任者&emsp;佐藤 昇（新潟大学医学部）
              </p>
              <div css={style.textBlock}>
                <p css={style.text}>
                現在、新潟大学医学部は富山大学医学部と連携し、「臨床医学と社会医学を駆使して地域を守る医療人の育成」を推進しています。日本では現在人口あたりの医師数は適正な数に近づいており、令和11年頃には医師の需給が均衡すると予想されています。しかしながら、地域レベルでは医師多数地域と医師少数地域の差は継続しており、この差の解消は容易なものではありません。このままでは医師少数地域では、地域の皆さんが必要な時に必要な医療を受けられないことになります。昨今世界中を席巻した新型コロナウイルス感染によるパンデミックでは医療ニーズが激変し、災害時の医療のように需要が供給を大きく上回りました。医師少数地域は正にそのような需給ギャップが生じている状態であり、このような医療需要に少数の医師で対応するためには、診療科の枠を超えた広い範囲の患者さんを診ることができる総合診療能力やご家族や地域、社会全体の視点を有する医師を育成する必要があります。
                </p>
                <p css={style.text}>
                富山大学と進めるこのプロジェクトでは、医師少数地区の病院・診療所で学生が地域に入って住民の皆さんや自治体の皆さんと関わりながら必要とされる臨床実習を経験し、将来の地域医療で必要とされる能力の獲得を目指します。多くの学生がこのプログラムで学び、将来地域で活躍するとともに、日本における地域医療人材育成のモデルとしてお役に立つことを願っています。
                </p>
              </div>
            </article>
          </div>
        </div>
      </Content>
    </section>
  )
}

export default Message
