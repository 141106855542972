import React from 'react'
import { style } from './PageHeader.style'
import Content from '../Content/Content'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/css'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()
const basePath = publicRuntimeConfig.basePath

const PageHeader: React.FC = () => {
  return (
    <header css={style.pageHeader} id="page_header">
      <div css={style.wrapSplide}>
        <Splide
          aria-label="私のお気に入りの画像集"
          options={{
            type: 'fade',
            speed: 2000,
            rewind: true,
            autoplay: true,
            interval: 4000,
            cover: true,
            height: '32.211vw',
            arrows: false,
          }}
          css={style.splide}
        >
          <SplideSlide>
            <img src={`${basePath}/home/img/Slider01.png`} />
          </SplideSlide>
          <SplideSlide>
            <img src={`${basePath}/home/img/Slider02.png`} />
          </SplideSlide>
          <SplideSlide>
            <img src={`${basePath}/home/img/Slider03.png`} />
          </SplideSlide>
          <SplideSlide>
            <img src={`${basePath}/home/img/Slider04.png`} />
          </SplideSlide>
        </Splide>
      </div>
      <Content css={style.content}>
        <p css={style.topText}>
          <span>ここで守る</span>
        </p>
        <h1 css={style.title}>
          <span>
            臨床医学と社会医学を駆使して
            <br />
            地域を守る医療人を養成します。
          </span>
        </h1>
        <div css={style.logoBlock}>
          <div css={style.wrapLogoText}>
            <p>立山連峰や妙高・越後三山などに象徴される山々、</p>
            <p>米どころ富山・新潟の広い平野、</p>
            <p>そして眼前に広がる厳しくも豊かな日本海。</p>
            <p>さらには、その隙間をぬうように山から平野、</p>
            <p>
              そして海へ続く名水を運ぶ信濃川・黒部川・阿賀野川・神通川などを
            </p>
            <p>「富山大学」「新潟大学」のスクールカラーで描いて</p>
            <p>私たちの守る「地域」をシンプルなロゴマークに表現しました。</p>
          </div>
          <img
            src={`${basePath}/home/img/icon.png`}
            alt="北越地域医療人養成センターのアイコン"
          />
        </div>
      </Content>
    </header>
  )
}

export default PageHeader
