import { css } from 'styled-components'
import { maxMq, minMq } from '../../shared/styles/breakPoints'
import variables from '../../shared/styles/variables'
import { getColor, Category } from '../../utils/news'

const items = css`
  align-items: flex-start;
  display: grid;
  gap: 10px 15px;
  grid-template-columns: 82px 1fr;
  padding: 15px 0;
  transition: transform .3s;

  ${minMq('MD')} {
    display: flex;
    gap: 18px;
    padding: 20px 0;
  }

  &.is-hover {
    transform:scale(0.98);
  }
`

const date = css`
  color: ${variables.GRAY};
  
  font-family: 'Lato', 'Noto Sans JP', sans-serif;
`

const categories = css`
  display: flex;
  gap: 8px;
  margin-bottom: 7px;
`

const category = (val: Category) => {
  return css`
    background-color: ${getColor(val)};
    border-radius: 4px;
    color: ${variables.WHITE};
    display: grid;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 24px;
    place-content: center;
    width: 60px;
  `
}

const title = css`
  font-weight: 400;
  
  ${maxMq('MD')} {
    grid-column: 1 / 3;
    grid-row: 2 / 3;
  }
`

export const style = {
  items,
  date,
  categories,
  category,
  title
}