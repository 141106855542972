import React from 'react'
import Content from '../Content/Content'
import { style } from './About.style'
import SectionTitle from '../SectionTitle/SectioinTitle'
import { commonStyle } from './Index.style'

const About = () => {
  return (
    <section
      css={[commonStyle.homeSection, commonStyle.bgcGray, style.aboutSection]}
    >
      <Content>
        <SectionTitle
          sectionSubTitle="ABOUT"
          sectionTitle="北越地域医療人養成センターとは"
        />
        <p css={style.mainText}>
          「地域と世界で活躍できる医療人を養成する」という共通のミッションを持って地域医療人材を養成してきた富山大学医学部と新潟大学医学部が、富山県から新潟県にまたがる広域ネットワークを形成して、両大学医学部の持つ教育ノウハウを共有して、地域を守る総合的な能力を持った医師を共同で養成します。
        </p>
      </Content>
    </section>
  )
}

export default About
