import { css } from 'styled-components'
import { maxMq, minMq } from '../../shared/styles/breakPoints'

const massageSection = css`
  padding-bottom: 40px;

  ${minMq('MD')} {
    padding-bottom: 95px;
  }
`

const box = css`
  align-items: flex-start;
  display: flex;
  gap: 40px;

  ${maxMq('LG')} {
    flex-direction: column;
  }

  + * {
    margin-top: 80px;

    ${minMq('MD')} {
      margin-top: 85px;
    }
  }
`

const img = css`
  width: 180px;
  
  ${maxMq('LG')} {
    display: block;
    margin-left: auto;
    margin-right: auto;
    order: -1;
  }
`

const textBlock = css`
  line-height: 1.72;
`

const title = css`
  font-weight: 700;
  margin-bottom: 17px;

  ${minMq('MD')} {
    margin-bottom: 32px;
  }
`

const name = css`
  font-weight: 700;
  margin-bottom: 16px;
  text-align: end;

  ${minMq('MD')} {
    margin-bottom: 32px;
  }
`

const text = css`
  text-indent: 1em;
`
const newLine = css`
  margin-top: 1.5em;
`

export const style = {
  massageSection,
  box,
  img,
  textBlock,
  title,
  name,
  text,
  newLine
}