import { css } from 'styled-components'
import { minMq } from '../../shared/styles/breakPoints'
import variables from '../../shared/styles/variables'


const homeSection = css`
  margin-bottom: 80px;
  position: relative;

  ${minMq('MD')} {
    margin-bottom: 120px;
  }
`

const lastSection = css`
  margin-bottom: 120px;

  ${minMq('MD')} {
    margin-bottom: 150px;
  }
`

const bgcGray = css`
  overflow-y: hidden;
  position: relative;
  
  &::before {
    background-color: ${variables.BASE_BGC};
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 41px;
    width: 100%;
    z-index: -1;

    ${minMq('MD')} {
      top: 60px;
    }
  }
`

const subHead = (color: "yellow" | "blue" | "green") => {
  let targetColor = ""
  switch (color) {
    case "yellow":
      targetColor = variables.LIGHT_YELLOW
      break;

    case "blue":
      targetColor = variables.SKY_BLUE
      break;

    case "green":
      targetColor = variables.LIGHT_SEAGREEN
      break;
  }

  return css`
    display: inline-table;
    font-size: 1.6rem;
    font-weight: 700;
    margin-bottom: 20px;

    ${minMq('MD')} {
      font-size: 2.4rem;
      height: 46px;
      margin-bottom: 30px;
    }

    > span {
      background: linear-gradient(transparent 55%, ${targetColor} 50%);
      padding: 0 10px 5px;

      ${minMq('MD')} {
        background: linear-gradient(transparent 41%, ${targetColor} 50%);
        padding: 0 20px 9px;
      }
    }
  `
}

export const commonStyle = {
  homeSection,
  lastSection,
  bgcGray,
  subHead
}