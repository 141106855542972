import React from 'react'
import { Tag, tagItems } from '../../utils/news'
import { style } from './NewsTag.style'

type OwnProps = {
  currentShowTag: Tag
  setCurrentShowTag: React.Dispatch<React.SetStateAction<Tag>>
  changeEvent?: React.ChangeEventHandler<HTMLInputElement>
}

const NewsTag: React.FC<OwnProps> = React.memo(
  ({ currentShowTag, setCurrentShowTag, changeEvent }) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setCurrentShowTag(e.target.value as Tag)
    }

    return (
      <div css={style.tags}>
        {tagItems.map((item) => {
          const { value, text } = item
          const id = 'category_' + value

          const checked = currentShowTag === value ? true : false

          return (
            <React.Fragment key={value}>
              <input
                type="radio"
                value={value}
                name="category"
                id={id}
                onChange={
                  changeEvent
                    ? (e) => {
                        handleChange(e)
                        changeEvent(e)
                      }
                    : handleChange
                }
                checked={checked}
              />
              <label
                htmlFor={id}
                css={
                  checked
                    ? [style.tag(value), style.currentTag(value)]
                    : style.tag(value)
                }
              >
                {text}
              </label>
            </React.Fragment>
          )
        })}
      </div>
    )
  },
)

NewsTag.displayName = 'NewsTag'

export default NewsTag
